import React, { useContext, useState, useEffect } from "react"
import Layout from "../components/layout"
import { Helmet } from "react-helmet"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import { MailIcon, PhoneIcon } from "@heroicons/react/solid"

import PageContext from "../context/PageContext"

const PoradenstviPage = props => {
  // Move this to it's own component consuming context.
  const SubMenu = () => {
    const [context] = useContext(PageContext)
    const [menu, setMenu] = useState([])

    useEffect(() => {
      if (context?.menu) {
        let newMenu = []
        let newMenuItem = {}
        let image = (
          <StaticImage
            src="../images/shutterstock_485743234.jpg"
            className=" transform  group-hover:scale-110 ease-in-out duration-150"
            alt="Malgen"
          />
        )
        context?.menu[42]?.children.map((menuItem, i) => {
          if (menuItem.url === "osobni-sluzby/") {
            image = (
              <StaticImage
                src="../images/osobni.jpg"
                className=" transform  group-hover:scale-110 ease-in-out duration-150"
                alt="Malgen"
              />
            )
          }
          if (menuItem.url === "sluzby-pro-volny-cas/") {
            image = (
              <StaticImage
                src="../images/volny.jpg"
                className=" transform  group-hover:scale-110 ease-in-out duration-150"
                alt="Malgen"
              />
            )
          }
          if (menuItem.url === "sluzby-pro-domacnost/") {
            image = (
              <StaticImage
                src="../images/pece.jpg"
                className=" transform  group-hover:scale-110 ease-in-out duration-150"
                alt="Malgen"
              />
            )
          }
          if (menuItem.url === "asistencni-sluzby/") {
            image = (
              <StaticImage
                src="../images/asistencni.jpg"
                className=" transform  group-hover:scale-110 ease-in-out duration-150"
                alt="Malgen"
              />
            )
          }

          newMenuItem = {
            menuName: menuItem.menuName,
            url: menuItem.url,
            image: image,
          }

          newMenu[i] = newMenuItem
        })

        setMenu(newMenu)
      }
    }, [context])

    return (
      <>
        {menu?.length > 1 &&
          menu.map((item, i) => (
            <Link
              key={i}
              to={"/" + item.url}
              className="group flex flex-col  text-center sm:border-0  relative m-3 overflow-hidden rounded-md border "
            >
              {item.image}
              <span className="truncate px-4 block  py-3 font-medium  text-gray-900 border border-gray-300  absolute  bottom-0 left-0 right-0 rounded-b-md backdrop-filter   bg-gray-200 group-hover:bg-white ease-in-out duration-150 ">
                {item.menuName}
              </span>
            </Link>
          ))}
      </>
    )
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Concierge | Holding Malgen</title>
      </Helmet>
      <main className="pb-8">
        {/* Hero card */}
        <div className="relative md:py-5">
          <div className="absolute inset-x-0 bottom-0 h-1/2 bg-gray-200" />
          <div className="max-w-7xl mx-auto  ">
            <div className="relative shadow-xl sm:rounded-lg sm:overflow-hidden">
              <div className="absolute inset-0">
                <StaticImage
                  src="../images/andre-hunter-297773-unsplash.jpg"
                  className="h-full w-full object-cover border border-gray-200 sm:rounded-lg"
                  alt="Malgen"
                />
                <div className="absolute inset-0 bg-gray-300 mix-blend-multiply" />
              </div>
              <div className="relative px-4 py-16 sm:px-6 sm:py-24 lg:py-32 lg:px-14">
                <h1 className="text-center lg:text-left text-3xl  md:text-5xl grid ">
                  <span className=" text-white">Concierge</span>
                  <span className=" text-white text-2xl"></span>
                </h1>
              </div>
            </div>
          </div>
        </div>
        <div className="  pt-12 sm:pt-16">
          <div className="mt-10 pb-12 bg-white sm:pb-16">
            <div className="relative">
              <div className="relative max-w-8xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="max-w-6xl mx-auto ">
                  <div className="rounded-lg  grid grid-cols-2 md:grid-cols-3 justify-items-center  overflow-hidden">
                    <SubMenu />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="py-12 bg-white">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <div className="lg:text-center">
                <h2 className="text-base text-blue-600 font-semibold tracking-wide uppercase">
                  Kávu platíme my
                </h2>
                <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                  Pomáháme firmám i jednotlivcům
                </p>
                <p className="mt-4 max-w-2xl text-lg text-gray-500 lg:mx-auto">
                  K vašim potřebám přistoupíme individuálně. Je to jednoduché,
                  zavolejte nám nebo pošlete e-mail. Na základě Vašich požadavků
                  zašleme nabídku, nebo si s vámi dáme schůzku u dobré kávy.
                </p>
              </div>

              <div className="mt-10 grid justify-start space-y-8 lg:flex lg:justify-center lg:space-x-8 lg:space-y-0">
                <div className="flex lg:justify-center items-center">
                  <div className="flex items-center justify-center h-12 w-12 rounded-md bg-blue-600 text-white">
                    <PhoneIcon className="h-6 w-6" aria-hidden="true" />
                  </div>
                  <p className="ml-3 text-xl  font-medium text-gray-900">
                    800 991 100
                  </p>
                </div>
                <div className="flex lg:justify-center items-center">
                  <div className="flex items-center justify-center h-12 w-12 rounded-md bg-blue-600 text-white">
                    <MailIcon className="h-6 w-6" aria-hidden="true" />
                  </div>
                  <a
                    href="mailto:office@malgen.cz"
                    className="ml-3 text-xl  font-medium text-gray-900"
                  >
                    office@malgen.cz
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  )
}

export default PoradenstviPage
